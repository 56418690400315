var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sabznbd" }, [
    _c("div", { staticClass: "intro" }, [
      _c("p", { staticClass: "download" }, [
        _vm._v(_vm._s(_vm.download_speed)),
      ]),
      _c("em", { class: `fas fa-${_vm.status}` }),
    ]),
    _vm.sabnzbdDetails.length > 0
      ? _c(
          "div",
          { staticClass: "details" },
          _vm._l(_vm.sabnzbdDetails, function (section, indx) {
            return _c(
              "div",
              { key: indx, staticClass: "info-wrap" },
              _vm._l(section, function (sabznbd) {
                return _c(
                  "p",
                  { key: sabznbd.label, staticClass: "info-line" },
                  [
                    _c("span", { staticClass: "lbl" }, [
                      _vm._v(_vm._s(sabznbd.label)),
                    ]),
                    _c("span", { staticClass: "val" }, [
                      _vm._v(_vm._s(sabznbd.value)),
                    ]),
                  ]
                )
              }),
              0
            )
          }),
          0
        )
      : _vm._e(),
    _vm.showQueue && _vm.sabnzbdQueue.length > 0
      ? _c("div", { staticClass: "details" }, [
          _c(
            "div",
            { staticClass: "info-wrap" },
            _vm._l(_vm.sabnzbdQueue, function (sabznbd) {
              return _c("p", { key: sabznbd.label, staticClass: "info-line" }, [
                _c("em", { class: `fas fa-${sabznbd.status}` }),
                _c("span", { staticClass: "lbl" }, [
                  _vm._v(_vm._s(sabznbd.filename)),
                ]),
                _c("span", { staticClass: "lbl" }, [
                  _vm._v(_vm._s(sabznbd.percentage) + "%"),
                ]),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm.sabnzbdQueue.length > 0
      ? _c(
          "p",
          { staticClass: "more-details-btn", on: { click: _vm.toggleQueue } },
          [
            _vm._v(
              " " + _vm._s(_vm.showQueue ? "Hide Queue" : "Show Queue") + " "
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }